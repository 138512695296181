import React from 'react'
import {withRouter} from 'react-router-dom';

class AnalyticsTracker extends React.Component {

  componentDidMount() {
    this.props.history.listen((location, action) => {
      window._paq.push(['setCustomUrl', location.pathname]);
      window._paq.push(['trackPageView']);
    });
  }

  render() {
    return null
  }
}

export default withRouter(AnalyticsTracker);
