import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './analytics';

// Show an error dialog if an error caused the react renderer to crash
const checkError = (eventId) => {
  window.setTimeout(()=> {
    // If nothing is rendered (error caused hard crash, show report dialog
    if(!document.querySelector("#root") || !document.querySelector("#root").hasChildNodes()){
      Sentry.showReportDialog({
        title: "It looks like an error occurred",
        eventId,
        // user: {
        //   name: "anonymous",
        //   email: "anonymous@user.com"
        // },
        subtitle: "",
        subtitle2: "If you’d like to help, tell us what happened below. Please note we only support current versions of Chrome-like, Firefox and Safari browsers at this time. Please disable your Adblocker before sending your feedback as it might block our error reporting tool.",
        labelSubmit: "Submit Error Report"
      });
    }
  }, 700)
}

Sentry.init({
  dsn: "https://61ac70a73a1f4ed896eb9595db3e1938@sentry.mgmt.gruppo-due.com/3",
  release: "g2-web@" + process.env.REACT_APP_HASH_VERSION,
  beforeSend(event, hint) {
    // Check if it is an exception
    if (event.exception ) {
      checkError(event.event_id);
    }
    return event;
  }
});

// Apply PointerEvent polyfill if necessary
if (!("PointerEvent" in window)) {
  console.log('Polyfilling pointer events')
  import("@wessberg/pointer-events")
    .then(() => {
      ReactDOM.render(<App/>, document.getElementById('root'));
    })
} else {
  ReactDOM.render(<App/>, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
