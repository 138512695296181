import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import cookie from "cookie-machine";
import AnalyticsTracker from "./components/AnalyticsTracker";
import LoadingComponent from "./components/LoadingComponent";
import SurveyPage from "./components/pages/SurveyPage";

// Code split components

const ConsentBar = React.lazy(() => import('./components/ConsentBar'))
const MainPage = React.lazy(() => import('./components/pages/MainPage'))
const LegalPage = React.lazy(() => import('./components/pages/LegalPage'))

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      consentBarVisible: false
    };
    console.log('🏔', process.env.REACT_APP_HASH_VERSION);
  }

  componentDidMount() {
    if (!cookie.get('g2-analytics-consent')) {
      this.setState({
        consentBarVisible: true
      })
    }
  }

  handleGiveConsent = () => {
    cookie.set('g2-analytics-consent', 'true');
    window._paq.push(['setConsentGiven']);
    this.setState({consentBarVisible: false})
  };


  render() {
    const {consentBarVisible} = this.state;
    return (
      <div className="App">
        <Router>

          <Switch>
            <Route path="/customer-survey" render={(props) =>
              <Suspense fallback={<LoadingComponent/>}>
                <SurveyPage {...props}/>
              </Suspense>
            }/>
            <Route path="/legal/:document" render={(props) =>
              <Suspense fallback={<LoadingComponent/>}>
                <LegalPage {...props}/>
              </Suspense>
            }/>
            <Route render={(props) =>
              <Suspense fallback={<LoadingComponent/>}>
                <MainPage {...props} consentBarVisible={consentBarVisible}/>
              </Suspense>
            }
            />
          </Switch>
          {
            consentBarVisible &&
            <Suspense fallback={null}>
              <ConsentBar onConsent={this.handleGiveConsent}/>
            </Suspense>
          }
          <AnalyticsTracker/>
        </Router>
      </div>
    );
  }
}

export default App;
