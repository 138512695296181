import React from "react"

const SurveyPage = () => {
  return (
    <div>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSe8yJ3-22345u1EYMZqx9fRaGCqR7TFxU_DVfjUHcr5x4FZZw/viewform?embedded=true"
        className="survey-frame" title="Customer Form" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
      </iframe>
    </div>
  )
}

export default SurveyPage
