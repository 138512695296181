import cookie from 'cookie-machine';

window._paq = window._paq || [];

// Require consent before any tracking
window._paq.push(['requireConsent']);

// Enable heart beat tracking
window._paq.push(['enableHeartBeatTimer', 15])

// Enable error tracking
// window._paq.push(['enableJSErrorTracking']);

// Check consent
if (cookie.get('g2-analytics-consent')) {
  console.log('Analytics consent given');
  window._paq.push(['setConsentGiven']);
}

// Load Countly script asynchronously
(function () {
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  window._paq.push(['trackPageView']);
  // window._paq.push(['enableLinkTracking']);
  (function () {
    var u = "//analytics.mgmt.gruppo-due.com/";
    window._paq.push(['setTrackerUrl', u + 'matomo.php']);
    window._paq.push(['setSiteId', '1']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
})();


//  Hook up manual link tracking
window.addEventListener('click', (e) => {
  if (e.target.nodeName === 'A') {
    const {href} = e.target;
    if (href && href.includes('mailto:service@gruppo-due.com')) {
      window._paq.push(['trackEvent', 'Contact', 'MailTo', href])
    } else if (
      href &&
      !href.match(
        new RegExp(
          `^(http://www.|https://www.|http://|https://)+(${window.location.hostname})`,
        ),
      )
    ) {
      window._paq.push(['trackLink', href, 'link'])
    } else if (href && e.target.hasAttribute('download')) {
      window._paq.push(['trackLink', href, 'download'])
    }
  }
})

